<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Empresas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Empresas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <!-- SE LLAMA A LA VISTA PARA UNA TABLA DINAMICA -->
                      <button
                        type="button"
                        class="btn btn-info"
                        @click="pivotTable()"
                        v-if="$store.getters.can('admin.empresas.pivotTable')"
                      >
                        <i class="fas fa-scroll"></i>
                      </button>
                      <!-- SE LLAMA EL MODAL DE EMPRESA EXPORT -->
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-form-empresa-export"
                        v-if="$store.getters.can('admin.empresas.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                        v-if="$store.getters.can('admin.empresas.create')"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table
                  id="users"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        Id
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.id"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Nit<input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.n_identificacion"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Razon Social
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.razon_social"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Representante Legal<input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.representante"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>Telefono</th>
                      <th>
                        Líneas Negocio
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="linea_negocio"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.lineas_negocio"
                          :filterable="true"
                          multiple
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in empresas.data" :key="item.id">
                      <td>
                        <a :href="uri_docs + item.logo" target="_blank">
                          <img
                            :src="uri_docs + item.logo"
                            alt="Logo"
                            width="75"
                            class="rounded mx-auto d-block"
                          />
                        </a>
                      </td>
                      <td>{{ item.id }}</td>
                      <td>{{ item.n_identificacion }}</td>
                      <td>{{ item.razon_social }}</td>
                      <td>{{ item.representante }}</td>
                      <td>{{ item.telefono }}</td>
                      <td class="text-center">
                        <div
                          v-for="lineas in item.lineas_negocios"
                          :key="lineas.id"
                        >
                          <span class="badge bg-navy">
                            {{ lineas.nombre }}
                          </span>
                        </div>
                      </td>
                      <td class="text-center">
                        <span
                          v-if="$store.getters.can('admin.empresas.show')"
                          class="badge"
                          data-toggle="modal"
                          data-target="#modal-form-estado"
                          style="cursor: pointer"
                          v-bind:class="[
                            item.estado == 1 ? 'badge-success' : 'badge-danger',
                          ]"
                          @click="llenarModal(item.id, item.estado)"
                        >
                          {{ item.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                        </span>
                        <span
                          v-else
                          class="badge"
                          v-bind:class="[
                            item.estado == 1 ? 'badge-success' : 'badge-danger',
                          ]"
                        >
                          {{ item.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                        </span>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="$store.getters.can('admin.empresas.show')"
                            @click="edit(item)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="$store.getters.can('admin.empresas.delete')"
                            @click="destroy(item.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="empresas.total">
                  <p>
                    Mostrando del <b>{{ empresas.from }}</b> al
                    <b>{{ empresas.to }}</b> de un total:
                    <b>{{ empresas.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="empresas"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <EmpresaEstado ref="EmpresaEstado" />
        <EmpresaExport ref="EmpresaExport" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import EmpresaEstado from "./EmpresaEstado";
import EmpresaExport from "./EmpresaExport";
import vSelect from "vue-select";

export default {
  name: "Empresas",
  components: {
    pagination,
    vSelect,
    EmpresaEstado,
    EmpresaExport,
  },
  data() {
    return {
      linea_negocio: [],
      filtros: {
        n_identificacion: null,
        razon_social: null,
        representante: null,
        tel1: null,
      },
      empresas: {},
      listasForms: {
        lineas_negocio: [],
        estados: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  methods: {
    getIndex(page = 1) {
      this.filtros.lineas_negocio = [];
      this.linea_negocio.forEach((linea) => {
        this.filtros.lineas_negocio.push(linea.id);
      });
      axios
        .get("/api/admin/empresas?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.empresas = response.data;
        });
    },

    getLineasNegocio() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocio = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    destroy(idEmpresa) {
      this.$swal({
        title: "Esta seguro de eliminar esta Empresa?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios.delete("/api/admin/empresas/" + idEmpresa).then(() => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se elimino la Empresa exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    llenarModal(id, estado) {
      if (this.$store.getters.can("admin.empresas.show")) {
        this.$refs.EmpresaEstado.llenar_modal(id, estado);
      }
    },

    create() {
      return this.$router.push({
        name: "/Admin/EmpresaForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    edit(empresa) {
      return this.$router.push({
        name: "/Admin/EmpresaForm",
        params: { accion: "Editar", data_edit: empresa, id: empresa.id },
      });
    },

    pivotTable() {
      return this.$router.push({
        name: "/Admin/EmpresaPivotTable",
      });
    },
    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
  },

  mounted() {
    this.getIndex();
    this.getLineasNegocio();
    this.getEstados();
    this.getMsg();
  },
};
</script>
